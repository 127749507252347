import React from "react"
import GiteList from "./GiteList"
import { useStaticQuery, graphql } from "gatsby"

const getGites = graphql`
  query {
    gites: allContentfulGite(sort: { fields: position, order: ASC }) {
      edges {
        node {
          titre
          slug
          id: contentful_id
          topImage {
            fluid {
              ...GatsbyContentfulFluid
            }
            title
          }
        }
      }
    }
  }
`

const Gites = () => {
  const { gites } = useStaticQuery(getGites)
  return <GiteList gites={gites} />
}

export default Gites
