import React, { Component } from "react"
import styles from "../../css/items.module.css"
import Gite from "./Gite"
import Title from "../Title"
import { FaInfoCircle } from "react-icons/fa"

export default class GiteList extends Component {
  state = {
    gites: [],
    sortedGites: [],
  }

  componentDidMount() {
    this.setState({
      gites: this.props.gites.edges,
      sortedGites: this.props.gites.edges,
    })
  }

  render() {
    return (
      <section className={styles.items}>
        <Title title="nos" subtitle="gîtes" />
        <article className={styles.info}>
          <h4>Profitez d'un lieu unique</h4>
          <p className={styles.desc}>
            Venez découvrir nos gîtes dans un endroit magnifique. Nous vous
            proposons différents gîtes pour toutes les envies. Le Moulin,
            authentique et plein de charme, l'atelier de peinture, atypique et
            écologique, et deux chambres très confortables. Nous souhaitons que
            vous passerez un excellent moment au Moulin de Gâteau !
          </p>
          <p>
            {" "}
            <FaInfoCircle className={styles.icon} /> Sélectionnez le gîte
            souhaité ci-dessous pour accéder aux détails de l'hébergement.
          </p>
        </article>
        <div className={styles.center}>
          {this.state.sortedGites.map(({ node }) => {
            return <Gite key={node.id} gite={node} />
          })}
        </div>
      </section>
    )
  }
}
