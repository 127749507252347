import React, { Component } from "react"
import Layout from "../components/Layout"
import StyledHero from "../components/StyledHero"
import Gites from "../components/Gites/Gites"
import { graphql } from "gatsby"
import SEO from "../components/SEO"

export default class gites extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Gîtes de charme à Saint-Pierre-les-Étieux" />
        <StyledHero img={this.props.data.defaultBcg.childImageSharp.fluid} />
        <Gites />
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
